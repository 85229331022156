import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  // error pages
  { path: '/error/403', name: 'page403', component: () => import('@/views/error/Page403.vue') },
  { path: '/error/404', name: 'page404', component: () => import('@/views/error/Page404.vue') },
  { path: '/error/500', name: 'page500', component: () => import('@/views/error/Page500.vue') },
  { path: '/', name: 'login', component: () => import('@/views/login/Login.vue') },

  { path: '/tools/record', name: 'toolsRecordTool', component: () => import('@/views/tools/ChatRecordTool.vue') },

  { path: '/tools/trpg/model', name: 'toolsTrpgModel', component: () => import('@/views/tools/TrpgModelEditor.vue') },
  { path: '/tools/maps' , name: 'toolsMaps', component: () => import('@/views/maps/MapsEdit.vue')},
  {
    path: '/tools/ninecard', name: 'NineCard', component: () => import('@/views/ffxiv/NineCard.vue'), children: [
      { path: '/tools/ninecard/home', name: 'NineCardHome', component: () => import('@/views/ffxiv/FFXIVNineCardHome.vue') },
      { path: '/card/edit', name: 'CardEdit', component: () => import('@/views/ffxiv/CardEdit.vue') },
      { path: '/cards/edit', name: 'CardsEdit', component: () => import('@/views/ffxiv/CardsEdit.vue') },
      { path: '/scheme/edit', name: 'SchemeEdit', component: () => import('@/views/ffxiv/SchemeEdit.vue') },


    ]
  },

  {
    path: '/dashboard', name: 'dashboard', component: () => import('@/views/Dashboard.vue'), children: [
      { path: '/dashboard/home', name: 'home', component: () => import('@/views/subpage/Home.vue') },
      { path: '/lib/infinite', name: 'Infinite', component: () => import('@/views/infinite/InfiniteCollection.vue') },
      { path: '/navigation/create/infinite', name: 'InfiniteCreateNavigation', component: () => import('@/views/role/createCardsNavigation.vue') },
      { path: '/feedback/list', name: 'FeedbackList', component: () => import('@/views/subpage/FeedbackList.vue') },
      { path: '/feedback/submit', name: 'FeedbackSubmit', component: () => import('@/views/subpage/FeedbackSubmit.vue') },
      { path: '/games/event', name: 'GamesEvent', component: () => import('@/views/games/event/Monitor.vue') },
      { path: '/history', name: 'History', component: () => import('@/views/subpage/History.vue') },
      { path: '/tarot', name: 'Tarot', component: () => import('@/views/subpage/Tarot.vue') },
      { path: '/control', name: 'Control', component: () => import('@/views/subpage/ControlPanel.vue') },
      { path: '/robot/token', name: 'RobotToken', component: () => import('@/views/robot/RobotToken.vue') },
      { path: '/books', name: 'books', component: () => import('@/views/books/BooksCollection.vue') }

    ]
  },




]

const router = new VueRouter({
  routes
})

export default router
